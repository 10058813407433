import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Typography from "@material-ui/core/Typography";
import { Grid, useMediaQuery } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { graphql, StaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { BackgroundImage } from "react-image-and-background-image-fade";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundImage: `url(/img/user.png)`,
    // minHeight: "330px",
    /*    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat", */
    height: 0,
    position: "relative",
    paddingTop: "33.33%",
  },

  container: {
    // minHeight: "320px",
    // backgroundSize: "100% 100%",
    background: "rgba(63, 92, 120, 0.46)",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    right: 0,
    zIndex: 10,
  },
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: "900",
    fontSize: "50px",
    letterSpacing: "5%",
    color: "#FFFFFF",
    width: "100%",
    textAlign: "center",
  },

  quotes: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: "900",
    fontSize: "60px",
    letterSpacing: "3%",
    color: "#FFFFFF",
    width: "100%",
  },
  desc: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: "300",
    fontStyle: "italic",
    fontSize: "20px",
    letterSpacing: "0.045em",
    color: "#EFEFEF",
    width: "100%",
    textAlign: "center",
  },
  user_name: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: "500",

    fontSize: "30px",
    letterSpacing: "0.045em",
    color: "#FFFFFF",
    width: "100%",
    textAlign: "center",
    marginTop: "3%",
  },

  user_title: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "15px",
    letterSpacing: "0.045em",
    color: "#FFFFFF",
    width: "100%",
    textAlign: "center",
  },
  newContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    //   height: "75vh",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 0,
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  // const maxSteps = tutorialSteps.length
  const [maxSteps, setMaxSteps] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  // const handleNext = () => {
  //   setActiveStep(prevActiveStep => prevActiveStep + 1)
  // }

  // const handleBack = () => {
  //   setActiveStep(prevActiveStep => prevActiveStep - 1)
  // }

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div>
      <div className={classes.newContainer}>
        <StaticImage
          src="../../assets/img/user.png"
          alt=""
          formats={["webp"]}
          // style={{ position: "relative" }}
          className={classes.root}
        />

        <div className={classes.container}>
          <Grid container>
            <Grid
              item
              md={2}
              xs={1}

              /*  style={{
              backgroundImage: `url(/img/flower.png)`,
              // minHeight: "320px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 150%",
            }} */
            >
              <StaticImage
                src="../../assets/img/flower.png"
                formats={["webp"]}
                alt="carouselImg2"
                style={{
                  backgroundSize: "cover",
                  paddingTop: "20px",
                  height: "80%",
                  width: "100%",
                }}
              />
              {/* <img src={carouselPic1} alt="sksk" /> */}
            </Grid>
            {
              //here
            }

            <StaticQuery
              query={graphql`
                {
                  allStrapiTestimonials {
                    edges {
                      node {
                        message
                        name
                        title
                      }
                    }
                  }
                }
              `}
              render={(data) => {
                setMaxSteps(data?.allStrapiTestimonials?.edges?.length);
                return (
                  <Grid
                    item
                    md={8}
                    xs={10}
                    style={{
                    //  display: "flex",
                      alignItems: "center",
                      direction: "column",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sm={12}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <AutoPlaySwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                      >
                        {data?.allStrapiTestimonials?.edges?.map(
                          (step, index) => (
                            <div
                              key={index}
                              style={{
                                minHeight: isMobile
                                  ? "90px"
                                  : isMD
                                  ? "200px"
                                  : "400px",
                              }}
                            >
                              {Math.abs(activeStep - index) <= 2 ? (
                                <div>
                                  <Typography
                                    variant="h2"
                                    className={classes.heading}
                                    style={{
                                      fontSize: isMobile
                                        ? "15px"
                                        : isMD && "30px",
                                      lineHeight: isMobile
                                        ? "15px"
                                        : isMD && "50px",
                                      marginTop: !isMobile && "3%",
                                    }}
                                  >
                                    Happy Students
                                  </Typography>

                                  <Typography
                                    variant="h2"
                                    className={classes.quotes}
                                    style={{
                                      fontSize: isMobile
                                        ? "30px"
                                        : isMD && "50px",
                                      lineHeight: isMobile
                                        ? "20%"
                                        : isMD && "90%",
                                    }}
                                  >
                                    “
                                  </Typography>

                                  <Typography
                                    variant="h2"
                                    className={classes.desc}
                                    style={{
                                      fontSize: isMobile
                                        ? "12px"
                                        : isMD && "20px",
                                      lineHeight: isMobile && "100%",
                                    }}
                                  >
                                    {step?.node?.message}
                                  </Typography>
                                  <Typography
                                    variant="h2"
                                    className={classes.user_name}
                                    style={{
                                      fontSize: isMobile
                                        ? "10px"
                                        : isMD && "25px",
                                      marginTop: isMobile && "1%",
                                    }}
                                  >
                                    {step?.node?.name}
                                  </Typography>

                                  {step?.node?.title && (
                                    <Typography
                                      variant="h2"
                                      className={classes.user_title}
                                      style={{
                                        fontSize: isMobile
                                          ? "10px"
                                          : isMD && "12px",
                                      }}
                                    >
                                      {step?.node?.title}
                                    </Typography>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          )
                        )}
                      </AutoPlaySwipeableViews>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sm={12}
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="dots"
                        activeStep={activeStep}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "transparent",
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              }}
            />

            <Grid
              item
              md={2}
              xs={1}
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              {/*    <img
              src={"/img/leaf.png"}
              alt="carouselImg2"
              style={{ float: "right", width: "100%" }}
              /> */}
              <StaticImage
                src="../../assets/img/leaf.png"
                formats={["webp"]}
                alt="carouselImg2"
                style={{ marginBottom: "100px" }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default SwipeableTextMobileStepper;
