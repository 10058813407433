import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Card from "./instructorsCard";
import InstructorProfileCard from "./instructorProfileCard";
import Button from "@material-ui/core/Button";
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Link } from "gatsby";
import { Typography } from "@material-ui/core";

function InstructorsSection({ data, dataLength, categories }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState("");

  const handleClickOpen = (e, data) => {
    e.preventDefault();
    setUserData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setUserData("");
    setOpen(false);
  };

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      <Grid item xs={12}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          minWidth={"sm"}
          open={open}
          onClose={handleClose}
          scroll={isMobile ? "body" : "paper"}
          aria-labelledby="responsive-dialog-title"
          classes={{ root: classes.dialogRoot }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <InstructorProfileCard
              data={userData}
              categories={categories}
              close={handleClose}
            />
          </DialogContent>
        </Dialog>
      </Grid>

      {data?.length > 0 ? (
        data.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                padding: isMobile && "10px 0px",
              }}
              // onClick={(e) => handleClickOpen(e, item)}
            >
              <div style={{ textDecoration: "none", width: "100%" }}>
                {/* <Link
                to={`${LIVE_URL}view-profile/${item?.id}`}
                style={{ textDecoration: "none", width: "100%" }}
                target="_self"
              > */}
                <Card data={item} />
              </div>
            </Grid>
          );
        })
      ) : (
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography component="div" className={classes.subHeading}>
            Coming soon
          </Typography>
        </Grid>
      )}

      <Grid
        item
        xs={8}
        sm={4}
        md={3}
        lg={2}
        xl={2}
        style={{ textAlign: "center", margin: "auto" }}
      >
        <Link
          to={`/view-all`}
          state={{ instructor: true }}
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          <Button aria-label="View all" className={classes.viewAllButton}>
            View all
          </Button>
        </Link>
      </Grid>
      {/*  <Grid
           item
           xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Link
           to={`${LIVE_URL}/view-all-teachers`}
          style={{ textDecoration: "none" }}
            target="_self"
          >
            <Button
            className={classes.viewMore}
              style={{ fontSize: isMobile && "10px" }}
          >
              View All
          </Button>
          </Link>
        </Grid> */}
    </Grid>
  );
}

export default InstructorsSection;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2%",
  },
  viewMore: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: theme.palette.primary.dark,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "129.69%",
  },
  subHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "23.34px",
    fontStyle: "normal",
  },
  dialogRoot: {
    "& .MuiDialogContent-root": {
      background: "#FFF",
    },
  },
  viewAllButton: {
    color: theme.palette.secondary.light,
    margin: "30px 0px 10px 0px",
    textAlign: "center",
    border: "1px solid #FF6035",
    borderRadius: "22px",
    backgroundColor: "transparent",
    padding: "7px 40px",
    width: "100%",
  },
}));
