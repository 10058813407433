import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import Card from "./coursesCard";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "@material-ui/core";
import { Link, navigate } from "gatsby";
import { LIVE_URL } from "../../utils/constants";
/* import CourseDetailCard from "./courseDetailCard"; */

function CoursesSection({ challenges, dataLength, isHome, configs }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = React.useState("");
  const sortedData = challenges?.sort(function (a, b) {
    return a.order - b.order;
  });

  // const handleClickOpen = (e, data) => {
  //   e.preventDefault();
  //   setCourseData(data);
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setCourseData("");
  //   setOpen(false);
  // };

  return (
    <Grid container>
      {/* <Grid item xs={12}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          minWidth={"sm"}
          open={open}
          onClose={handleClose}
          scroll={isMobile ? "body" : "paper"}
          aria-labelledby="responsive-dialog-title"
          classes={{ root: classes.dialogRoot }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <CourseDetailCard data={courseData} close={handleClose} />
          </DialogContent>
        </Dialog>
      </Grid> */}

      <Grid item={12} container spacing={isMobile ? 0 : 2}>
        {sortedData?.length > 0 ? (
          sortedData
            .slice(0, isHome ? 8 : sortedData.length)
            .map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    padding: "10px 10px",
                  }}
                  // onClick={(e) => handleClickOpen(e, item)}
                >
                  {/* <div
                  // to={`courses/${item?.slug}`}
                  // to={`courses/${item?.slug}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`courses/${item?.slug}`);
                  }}
                  style={{ textDecoration: "none", width: "100%" }}
                  // target="_self"
                > */}
                  <div
                    style={{ textDecoration: "none", width: "100%" }}
                    // target="_self"
                  >
                    <Card data={item} configs={configs} />
                  </div>
                </Grid>
              );
            })
        ) : (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            Coming soon
          </Grid>
        )}
      </Grid>
      {isHome && (
        <Grid
          item
          xs={8}
          sm={4}
          md={3}
          lg={2}
          xl={2}
          style={{ textAlign: "center", margin: "auto" }}
        >
          <Link
            to={`${process.env.REACT_APP_BASE_URL}/view-all-challenges`}
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <Button aria-label="View all" className={classes.viewAllButton}>
              View all
            </Button>
          </Link>
        </Grid>
        // <Grid
        //   item
        //   xs={12}
        //   style={{ display: "flex", justifyContent: "flex-end" }}
        // >
        //   <Link
        //     to={`${LIVE_URL}view-all-courses`}
        //     style={{ textDecoration: "none" }}
        //     target="_blank"
        //   >
        //     <Button
        //       className={classes.viewMore}
        //       style={{ fontSize: isMobile && "10px" }}
        //     >
        //       View All
        //   </Button>
        //   </Link>
        // </Grid>
      )}
    </Grid>
  );
}

export default CoursesSection;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2%",
  },
  viewMore: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: theme.palette.primary.dark,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "129.69%",
  },
  viewAllButton: {
    color: theme.palette.secondary.light,
    margin: "30px 0px 10px 0px",
    textAlign: "center",
    border: "1px solid #FF6035",
    borderRadius: "22px",
    backgroundColor: "transparent",
    padding: "7px 40px",
    width: "100%",
  },
}));
