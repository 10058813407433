import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { StaticImage } from "gatsby-plugin-image";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import useFetch from "use-http";
import { RELATE_APP_BASE_URL } from "../../utils/constants";
import Truncate from "react-truncate";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import { Link, navigate } from "gatsby";
import CourseDetailCard from "./courseDetailCard";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
    background: "#FFF",
  },
  media: {
    flex: 1.3344,
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "flex",
    alignItems: "center",
  },

  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "19px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
  },
  planTooltip: {
    position: "absolute",
    bottom: "10px",
    background: "#4DB051",
    right: "0px",
    borderRadius: "13px",
    padding: "0px 4px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#fff",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
    width: "150px",
  },
  subscriptionBtn: {
    fontSize: "20px",
    lineHeight: "28px",
    // display: "flex",
    // justifyContent: "center",
    minWidth: "90px",
    textDecoration: "none",
    zIndex: "10",
  },
  btnContainer: {
    position: "absolute",
    bottom: "62px",
    display: "flex",
    justifyContent: "space-between !important",
    width: "95%",
    alignItems: "center",
    paddingLeft: "5px",
  },
  perviewBtnContainer: {
    fontSize: "20px",
    lineHeight: "28px",
    // display: "flex",
    // justifyContent: "center",
    width: "90px",
    textDecoration: "none",
    zIndex: "10",
  },
  // perviewBtnContainer: {
  //   fontSize: "20px",
  //   lineHeight: "28px",
  //   // display: "flex",
  //   // justifyContent: "center",
  //   width: "90px",
  //   textDecoration: "none",
  //   zIndex: "10",
  // },
  perviewBtn: {
    textTransform: "none",
    // width: "100%",
    // height: "67px",
    // background: theme.palette.secondary.light,
    // background: "transparent",
    borderRadius: "59px",
    background: "#FF6035",
    border: "2px solid transparent",
    color: "#FFF !important",
    // border: "2px solid transparent",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    height: "35px",
    // color: "#000",
    transition: "background 1s",
    width: "100px",
    // background: theme.palette.secondary.light,
    // border: "2px solid #FF6035",

    // "&:hover": {
    //   background: "#FF6035",
    //   border: "2px solid transparent",
    //   color: "#FFF !important",
    // },
  },
  previewButton: {
    display: "none",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
  previewButtonDisplay: {
    display: "block",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
  button1: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
    //    height: '52px'
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#C2C2C2",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  mediaImage: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    aspectRatio: 16 / 9,
  },
}));

function CourseCard({ data, configs }) {
  const classes = useStyles();
  /*  const [configs, setConfigs] = useState(null); */
  const { get, response } = useFetch(`${process.env.REACT_APP_API_URL}`);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [previewBtnShow, setPreviewBtnShow] = useState({ display: "none" });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const large = useMediaQuery("(min-width:1400px)");

  // const largeDevice = useMediaQuery(theme.breakpoints.down("lg"));

  const [image, setImage] = useState("");
  const [imageSrcPosition, setImageSrcPosition] = useState();

  const [displayHover, setDisplayHover] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = React.useState("data");

  const handleClickOpen = (e, data) => {
    e.stopPropagation();
    setCourseData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setCourseData("");
    setOpen(false);
  };

  /*   function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
        setIsLoading(false);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };

      let position = src?.search("updatedAt");
      if (position > 1) {
        setImageSrcPosition(position);
      }
      // console.log(src);

      var text = ext[2] + "_thumb";
      ext.splice(2, 1, text);
      let newUrl = text + ".webp";
      newUrl = newUrl.split("/");
      newUrl.splice(0, 1);
      newUrl = newUrl.join("/");
      setImage(src);
      // setImage(newUrl);
    });
  }

  useEffect(() => {
    preloadImage(`${RELATE_APP_BASE_URL}${data?.image}`);
  }, []); */

  // debugger;

  /*  useEffect(() => {
    async function fetchConfigs() {
      try {
        const _configs = await get("/config/");

        if (response.ok) {
          let obj = {};
          for (const item of _configs?.data) {
            obj = { ...obj, [item.key]: item.value };
          }
          setConfigs(obj);
        } else {
          fetchConfigs();
        }
      } catch (e) {}
    }

    fetchConfigs();
  }, []); */

  // console.log(image);

  // var urlP = image + `?dim=300*300`;

  // console.log(image + `?dim=300*300`);

  // // debugger;

  return (
    <div style={{ position: "relative" }}>
      <Grid item xs={12}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          minWidth={"sm"}
          open={open}
          onClose={handleClose}
          scroll={isMobile ? "body" : "paper"}
          aria-labelledby="responsive-dialog-title"
          // classes={{ root: classes.dialogRoot }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <CourseDetailCard
              data={courseData}
              configs={configs}
              imageSrc={`${RELATE_APP_BASE_URL}${data?.image}`}
              // imageSrc={
              //   imageSrcPosition > 1
              //     ? image + `&dim=${300}x${150} `
              //     : image + `?dim=${300}x${150} `
              // }
              // imageSrc={`${RELATE_APP_BASE_URL}/${image}`}
              close={handleClose}
            />
          </DialogContent>
        </Dialog>
      </Grid>
      {/* <div
        // to={`courses/${item?.slug}`}
        // to={`courses/${item?.slug}`}
        onClick={(e) => {
          // e.stopPropagation();
          navigate(`/courses/${data?.slug}`);
        }}
        // style={{ textDecoration: "none", width: "100%" }}
        // target="_self"
      > */}
      <Link
        to={`/courses/${data?.slug}/`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Card
          className={classes.root} /* style={{ height: !isMobile && "45vh"}} */
          onMouseOver={() => setDisplayHover(true)}
          onMouseLeave={() => setDisplayHover(false)}
        >
          {/*  {isLoading ? (
            <div
              style={{
                width: 200,
                height: 150,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Skeleton width={"24px"} height={"24px"} background={"black"} />
              <Skeleton animation={false} width={"24px"} height={"24px"} />
              <Skeleton animation="wave" width={"24px"} height={"24px"} />
            </div>
          ) : null} */}

          <div className={classes.media}>
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={325}
              />
            ) : null}
            <img
              src={`${RELATE_APP_BASE_URL}${data?.image}`}
              // src={
              //   imageSrcPosition > 1
              //     ? image + `&dim=${300}x${150} `
              //     : image + `?dim=${300}x${150} `
              // }
              // src={`${RELATE_APP_BASE_URL}/${image}`}
              /*   onLoad={() => {
                setIsLoading(false);
              }} */
              onError={(err) => {
                setIsLoading(false);
                setIsError(true);
              }}
              className={classes.mediaImage}
              alt={`${data?.name}`}
              style={{
                height: !isMobile && "100%",
                maxHeight: !isMobile && "100%",
                //   display: isLoading ? "none" : "block"
              }}
              loading="lazy"
            />
          </div>

          <CardContent
            style={{
              backgroundColor: "#FFFFFF ",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px",
              paddingTop: "8px",
              maxHeight: "50px",
              height: "50px",
              overflow: "hidden",
            }}
            onMouseOver={() => setDisplayHover(true)}
            onMouseLeave={() => setDisplayHover(false)}
          >
            <Tooltip title={data?.name} aria-label="add" placement="bottom-end">
              <Typography
                variant="h2"
                className={classes.heading}
                style={{ fontSize: isMobile && "18px" }}
              >
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {/*  {`   ` + data?.name + `   `} */}
                  {`   ${data?.name}  `}
                </Truncate>
              </Typography>
            </Tooltip>
          </CardContent>
        </Card>
      </Link>
      {/* </div> */}
      <div
        onMouseOver={() => setDisplayHover(true)}
        onMouseLeave={() => setDisplayHover(false)}
        className={classes.btnContainer}
      >
        <div className={classes.perviewBtnContainer}>
          <Button
            aria-label="Preview"
            variant="contained"
            color="default"
            style={{ backgroundColor: "#FF6035", borderRadius: "35px" }}
            className={
              displayHover || isMobile
                ? classes.previewButtonDisplay
                : classes.previewButton
            }
            onClick={(e) => handleClickOpen(e, data)}
            /*  onMouseOver={() => setDisplayHover(true)}
            onMouseLeave={() => setDisplayHover(false)} */
            // startIcon={<PlayArrow />}
          >
            Preview
          </Button>
        </div>
        <div className={classes.subscriptionBtn}>
          <Link
            target="_blank"
            style={{ textDecoration: "none" }}
            to={
              data?.plan?.name === "Subscription"
                ? `/subscription-plans`
                : `${RELATE_APP_BASE_URL}/view-course/${data?.slug}?tab=signup`
            }
          >
            <Button
              aria-label="Join Now"
              className={classes.button1}
              style={{
                backgroundColor: "#4DB051",
                borderRadius: "35px",
                minWidth: "90px",
              }}
            >
              {data?.plan?.name === "Paid"
                ? `Buy Premium: ${configs?.currency}${data?.price}`
                : data?.plan?.name === "Subscription"
                ? "Subscribe"
                : data?.plan?.name === "Donation"
                ? "Free"
                : data?.plan?.name}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default CourseCard;
