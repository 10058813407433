import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Courses from "./challengesSection";
import { useMediaQuery, Grid } from "@material-ui/core";

function TabPanel(props) {
  const theme = useTheme();
  const { children, value, index, ...other } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabPanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabPanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-root": {
      fontFamily: theme.palette.fontFamily.Reef,
      fontWeight: "500",
      fontSize: "32px",
      textTransform: "capitalize",
      minWidth: "auto",
      padding: "0px 15px",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#F28600",
      borderBottom: "none",
    },
  },

  smTabs: {
    "& .MuiTab-root": {
      fontFamily: theme.palette.fontFamily.Reef,
      fontWeight: "500",
      fontSize: "24px",
      textTransform: "capitalize",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#F28600",
      borderBottom: "none",
    },
  },
  appBar: {
    backgroundColor: "rgb(251, 251, 251)",
    boxShadow: "none",
    flexDirection: "row",
    justifyContent: "center",
    "& .MuiButtonBase-root": {
      color: "black",
    },
  },
}));

export default function ScrollableTabsButtonAuto({
  challenges,
  isHome,
  configs,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {/*    <AppBar
        position="static"
        color="default"
        className={classes.appBar}
        style={{ backgroundColor: isMobile && "#FFF" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          // style={{
          //   width: isMobile ? "80%" : "50%",

          // }}
          className={isMobile ? classes.smTabs : classes.tabs}
        >


          {categories?.length > 0 &&
            categories.map((item, index) => {
              return (
                <Tab label={item.name} {...a11yProps(index)} key={index} />
              );
            })}
        </Tabs>
      </AppBar>
 */}
      <Grid
        style={{
          padding: isMobile ? "20px 5px 20px 5px" : "20px 30px 20px 30px",
        }}
      >
        <Courses
          //   categoryID={item?.id}
          challenges={challenges}
          // courses={item?.courses?.slice(0, 16)}
          dataLength={challenges?.length}
          isHome={isHome}
          configs={configs}
        />
      </Grid>
    </div>
  );
}
