import React from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import Instructors from "./instructorSection"
import { useMediaQuery } from "@material-ui/core"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanell-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanell-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-root": {
      fontFamily: theme.palette.fontFamily.Reef,
      fontWeight: "500",
      fontSize: "24px",
      textTransform: "capitalize",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#F28600",
      borderBottom: "none",
    },
  },

  smTabs: {
    "& .MuiTab-root": {
      fontFamily: theme.palette.fontFamily.Reef,
      fontWeight: "500",
      fontSize: "15px",
      textTransform: "capitalize",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#F28600",
      borderBottom: "none",
    },
  },
  appBar: {
    backgroundColor: "rgb(251, 251, 251)",
    boxShadow: "none",
    flexDirection: "row",
    justifyContent: "center",
    "& .MuiButtonBase-root": {
      color: "black",
    },
  },
}))

export default function ScrollableTabsButtonAuto ({ studios, instructors, categories }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root} style={{ background: isMobile && '#FFF' }}>

      <TabPanel
        value={value}
        index={0}
        style={{ backgroundColor: isMobile ? '#FFF' : "rgb(251, 251, 251)", padding: isMobile ? '20px 5px 20px 5px' : '20px 30px 20px 30px' }}
      >
        <Instructors
          data={instructors?.slice(0, 12)}
          categories={categories}
          dataLength={instructors?.length}

        />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        style={{ backgroundColor: "rgb(251, 251, 251)" }}
      >
        <Instructors data={studios?.slice(0, 12)} dataLength={studios?.length} />
      </TabPanel>
    </div >
  )
}
