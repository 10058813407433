import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Courses from "./coursesSection";
import {
  useMediaQuery,
  TextField,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import * as JsSearch from "js-search";
import CustomButton from "../common/button";
function TabPanel(props) {
  const theme = useTheme();
  const { children, value, index, ...other } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabPanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabPanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-root": {
      fontFamily: theme.palette.fontFamily.Reef,
      fontWeight: "500",
      fontSize: "32px",
      textTransform: "capitalize",
      minWidth: "auto",
      padding: "0px 15px",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#F28600",
      borderBottom: "none",
    },
  },

  smTabs: {
    "& .MuiTab-root": {
      fontFamily: theme.palette.fontFamily.Reef,
      fontWeight: "500",
      fontSize: "24px",
      textTransform: "capitalize",
    },
    "& .MuiTab-textColorPrimary": {
      color: "black",
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#F28600",
      borderBottom: "none",
    },
  },
  appBar: {
    backgroundColor: "rgb(251, 251, 251)",
    boxShadow: "none",
    flexDirection: "row",
    justifyContent: "center",
    "& .MuiButtonBase-root": {
      color: "black",
    },
  },
  searchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(9, 169, 187, 0.1)",
      color: "rgba(124, 124, 124, 0.25)",
      fontFamily: theme.palette.fontFamily.Reef,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px 30px",
      height: "58px",
      alignSelf: "center",
      position: "static",
      marginBottom: "20px",
      width: "100%",
      marginTop: "20px",
      borderRadius: "10px",
      boxShadow: "inset 0px 6px 6px rgba(0, 65, 72, 0.05)",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: theme.palette.secondary.light,
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },
}));

export default function ScrollableTabsButtonAuto({
  categories,
  isHome,
  configs,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filteredData, setFilteredData] = useState("");
  const [allData, setAllData] = useState("");
  var search = new JsSearch.Search("name");
  search.addIndex("name");
  search.addIndex("seoDescription");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* const handleSearch = (e) => {
    if (e.target.value !== "") {
      let result = search.search(e.target.value);
      console.log(result,"&&&")
      setFilteredData(result);

    } else {
      setFilteredData(allData);
    }
  }; */
  /*    useEffect(() => {

      let array = [];
      let obj = categories[0].courses.map((item) => {
        console.log(item, "item")
      });
      console.log(array, "array")
      setFilteredData(array);
      setAllData(array);

  }, [categories]);
 */
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        className={classes.appBar}
        style={{ backgroundColor: isMobile && "#FFF" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          // style={{
          //   width: isMobile ? "80%" : "50%",

          // }}
          className={isMobile ? classes.smTabs : classes.tabs}
        >
          {/* <Tab label="Meditation" {...a11yProps(0)} />
          <Tab label="Breathing" {...a11yProps(1)} />
          <Tab label="Yoga" {...a11yProps(2)} />
          <Tab label="Tantra" {...a11yProps(3)} /> */}

          {categories?.length > 0 &&
            categories.map((item, index) => {
              return (
                <Tab label={item.name} {...a11yProps(index)} key={index} />
              );
            })}
        </Tabs>
      </AppBar>

      {categories?.length > 0 &&
        categories.map((item, index) => {
          return (
            <TabPanel
              value={value}
              index={index}
              key={index}
              style={{
                backgroundColor: isMobile ? "#FFF" : "rgb(251, 251, 251)",
                padding: isMobile ? "20px 5px 20px 5px" : "20px 30px 20px 30px",
                borderBottom: "0px solid transparent",
              }}
            >
              {/*  <Grid container justify="center">
            <Grid
              item
              xs={12}
              xs={12}
              md={6}
              container
              justify="center"
              spacing={isMobile ? 1 : 2}
              style={{
                padding: isMobile ? "20px 5px" : "20px 30px",
              }}
            >

                <>
                  <Grid item xs={8} sm={8} md={9} lg={9}>
                    <TextField
                      className={classes.searchField}
                      type="Search"
                      style={{ width: "100%" }}
                      placeholder="Search..."
                      name="search"
                      onChange={handleSearch}
                      InputProps={{
                        endAdornment: <Search />,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={2}
                    lg={2}
                    style={{ position: "relative" }}
                  >
                    <div style={{ maxWidth: "95%" }}>
                      <CustomButton
                        btnText="Search"
                        height="58px"
                        marginTop="20px"
                      />
                    </div>

                  </Grid>
                </>

            </Grid>
          </Grid>
 */}

              <Courses
                categoryID={item?.id}
                courses={item?.courses}
                // courses={item?.courses?.slice(0, 16)}
                dataLength={item?.courses?.length}
                isHome={isHome}
                configs={configs}
              />
            </TabPanel>
          );
        })}
    </div>
  );
}
